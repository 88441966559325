<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol class="col-xl-4" md="8">

          <CCard class="pt-4 pl-4 pr-4">
            <CCardBody>
              <CForm>
                <CCol class="text-center">
                  <h1>Giriş</h1>
                  <p class="text-muted">Giriş Yapmak İçin Bilgilerinizi Girin.</p>
                </CCol>
                <CInput
                    placeholder="E-mail"
                    autocomplete="email"
                    v-model="email"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                    placeholder="Şifre"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="12" class="text-center mb-3">
                    <CButton color="success" @click="login">
                      Giriş
                    </CButton>
                  </CCol>
                </CRow>
                <CCol class="text-right">
                  <small><u><a href="https://advancerenglish.com" target="_blank">AdvancerEnglish.com</a></u></small>
                </CCol>
              </CForm>
            </CCardBody>
          </CCard>

        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import {Administrator} from '@/services/api-service';
import {localStorageHelper} from '../helpers/localStorage';

export default {
  name: 'Login',
  mounted() {
    if( localStorageHelper.UserJwt !== null ) {
      this.$router.replace('/dashboard');
    }
  },
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false
    }
  },
  methods: {
    async login() {
      this.isLoggingIn = true;
      await Administrator.adminLogin(this.email, this.password);
      this.isLoggingIn = false;
      await this.$router.replace('/dashboard');
    }
  }
}
</script>
